import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/share",
    name: "share",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/share/Share.vue"),
  },
  {
    path: "/providers",
    name: "providers",
    component: () => import("../views/providers/ProviderBase.vue"),
    children: [
      {
        path: "earning",
        name: "providerEarningBase",
        meta: {
          title: "Provider earning",
        },
        component: () => import("../views/earnings/ProviderEarningBase.vue"),
      },
      {
        path: "payment-methods",
        name: "paymentMethods",
        meta: {
          title: "Payment methods Index",
        },
        component: () => import("../views/paymentMethods/PaymentMethods.vue"),
        children: [
          {
            path: "new",
            name: "paymentMethodsNew",
            meta: {
              title: "Payment methods New",
            },
            component: () =>
              import(
                "../views/paymentMethods/Actions/New/NewPaymentMethod.vue"
              ),
          },
          {
            path: "edit",
            name: "paymentMethodsEdit",
            meta: {
              title: "Payment methods New",
            },
            component: () =>
              import(
                "../views/paymentMethods/Actions/Edit/EditPaymentMethod.vue"
              ),
          },
          {
            path: "",
            name: "paymentMethodsIndex",
            meta: {
              title: "Payment methods Index",
            },
            component: () =>
              import(
                "../views/paymentMethods/Actions/Index/IndexPaymentMethods.vue"
              ),
          },
        ],
      },
      {
        path: "registrations",
        name: "registrations",
        component: () => import("../views/providers/ProviderBase.vue"),
        children: [
          {
            path: "provider",
            name: "providersRegistration",
            meta: {
              title: "Providers Registration",
            },
            component: () =>
              import(
                "../views/providersRegistration/ProvidersRegistration.vue"
              ),
          },
          {
            path: "vehicle",
            name: "vehicleRegistration",
            meta: {
              title: "Vehicle Registration",
            },
            component: () =>
              import("../views/vehicleRegistration/VehicleRegistration.vue"),
          },
        ],
      },
      {
        path: "vehicles",
        name: "vehicles",
        component: () => import("../views/providers/ProviderBase.vue"),
        children: [
          {
            path: "",
            name: "providersVehiclesIndex",
            meta: {
              title: "Provider's Vehicles",
            },
            component: () => import("../views/vehiclesIndex/VehicleIndex.vue"),
          },
        ],
      },
      {
        path: "settlement",
        name: "settlement",

        component: () => import("../views/providers/ProviderBase.vue"),
        children: [
          {
            path: "",
            name: "providerSettlementBase",
            meta: {
              title: "Provider settlement",
            },
            component: () => import("../views/settlement/SettlementBase.vue"),
          },
          {
            path: "pago-movil",
            name: "providerSettlementPagoMovil",
            meta: {
              title: "Provider settlement pago-movil",
            },
            component: () =>
              import("../views/settlement/partials/PagoMovil.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/notifications",
    name: "notifications",
    component: () => import("../views/notifications/NotificationBase.vue"),
    children: [
      {
        path: "",
        name: "notificationBase",
        meta: {
          title: "Notifications",
        },
        component: () =>
          import("../views/notifications/pages/UsersNotificationBase.vue"),
      },
      {
        path: "blog",
        name: "blog",
        meta: {
          title: "Blog",
        },
        component: () => import("../views/notifications/pages/Blog.vue"),
      },
    ],
  },
  {
    path: "/tip-success",
    name: "TipSuccess",

    component: () =>
      import(
        /* webpackChunkName: "tipSuccess" */ "../views/tips/TipSuccess.vue"
      ),
  },
  {
    path: "/rewind",
    name: "Rewind",

    component: () =>
      import(/* webpackChunkName: "rewind" */ "../views/rewind/Rewind.vue"),
  },
  {
    path: "/category",
    component: () => import("../views/category/Category.vue"),
    children: [
      {
        path: "",
        name: "homeCategory",
        meta: {
          title: "Category",
        },
        component: () => import("../views/category/pages/Home.vue"),
      },
      {
        path: "benefits/",
        name: "benefits",
        meta: {
          title: "Benefits",
        },
        component: () => import("../views/category/pages/Benefits.vue"),
      },
      {
        path: "benefits/:request",
        name: "BenefitsRequet",
        meta: {
          title: "Request",
        },
        component: () => import("../views/category/pages/Request.vue"),
      },
      {
        path: "benefits/:request/codes",
        name: "PromoCodes",
        meta: {
          title: "Promo codes",
        },
        component: () => import("../views/category/pages/Codes.vue"),
      },
      {
        path: "faqs/",
        name: "faqs",
        meta: {
          title: "faqs",
        },
        component: () => import("../views/category/pages/Faqs.vue"),
      },
    ],
  },
  {
    path: "/maintenance",
    name: "Maintenance",

    component: () =>
      import(
        /* webpackChunkName: "about" */ "../views/maintenance/Maintenance.vue"
      ),
  },
  {
    path: "/polls",
    name: "Polls",

    component: () =>
      import(/* webpackChunkName: "about" */ "../views/polls/Polls.vue"),
  },
  {
    path: "/rcv",
    name: "Rcv",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/rcv/RcvView.vue"),
  }
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior: (to, from, savedPosition) => {
    return { x: 0, y: 0 };
  },
});

export default router;
